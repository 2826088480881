<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card style="margin-bottom: 30px" v-if="selected == null">
          <v-card-title>
            Inspection
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            @click:row="clicked"
            :items="pending"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">
          <b-card class="mt-3" header="Info Order">
            <b-form>
              <b-form-group id="input-group-2" label="ID:" label-for="i1">
                <b-form-input id="i1" v-model="selected.id" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Type Cleaning:" label-for="i2">
                <b-form-input id="i2" v-model="selected.type" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Date Cleaning:" label-for="i3">
                <b-form-input id="i3" v-model="selected.date" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Name Worker:" label-for="i3">
                <b-form-input id="i3" v-model="selected.worker_name" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Name Client:" label-for="i3">
                <b-form-input id="i3" v-model="selected.client_name" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Email Client:" label-for="i3">
                <b-form-input id="i3" v-model="selected.client_email" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Phone Client:" label-for="i3">
                <b-form-input id="i3" v-model="selected.client_phone" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Value:" label-for="i5">
                <b-form-input id="i5" v-model="selected.value" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Value Ind. Contractor:" label-for="i6">
                <b-form-input id="i6" v-model="selected.value_final" disabled></b-form-input>
              </b-form-group>

              

              <b-button
                type="button"
                @click="selected = null"
                variant="danger"
                style="margin-right: 20px;"
              >Return</b-button>
            </b-form>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "inspection-list",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },
    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  data() {
    return {
      pending: [],
      selected: null,
      search: "",
      headers: [
        { text: "Order ID", value: "id" },
        { text: "Independent Contractor", value: "worker_name" },
        { text: "LC Value", value: "value" },
        { text: "IC Value", value: "value_final" }
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if (auth != null && auth != "undefined") {
      console.log("existe");
    } else {
      console.log("nao existe");
      this.$router.push({ name: "login" });
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readPendingAdmin")
      .then(res => res.json())
      .then(data => {
        this.pending = data.pending;
        console.log(data.pending);
      })
      .catch(console.log);
  }
};
</script>
